import { useTranslation } from "react-i18next";

function Remerciements() {
    const { t } = useTranslation();

    return (
        <div>
            <h2 className="py-3 fw-bolder text-center text-danger">
                {t("home.thankyou")}
            </h2>
            <p className="py-2 text-center">
                {t("home.thankyouvolunteers")}
            </p>
            <p className="py-1 text-center">
                {t("home.seeyounexttime")}
            </p>
        </div>
    )
}

export default Remerciements;

