import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import SideBySidePictures from "../components/SideBySidePictures";
import SideBySidePicturesKids from "../components/SideBySidePicturesKids";
import ButtonxPayrience from "../components/ButtonxPayrience";
import CustomImage from "../components/CustomImage";
import Remerciments from "../components/Remerciments";

/**
 * Page d'acceuil
 * @return {JSX.Element}
 */
function Home() {
    const { t } = useTranslation();

    const imageEnfant1 = {
        src: "/images/enfant1image11.jpg",
        alt: 'Brandon',
        ville: 'St-Georges de Beauce',
        age: '13 ',
    };

    const imageEnfant2 = {
        src: '/images/enfant2image1.jpg',
        alt: 'Noah',
        ville: 'Bedford',
        age: '17 ',
    };

    const imageTruck1 = { src: "/images/truckVedette1.jpg", alt: "Camion vedette 1" };
    const imageTruck2 = { src: "/images/truckVedette2.jpg", alt: "Camion vedette 2" };

    const imageEdition2022 = {
        src: "/images/TruckNRollEdition2022.jpg",
        alt: "Truck N Roll En Coeur Edition 2022"
    };

    const imageEdition2023 = {
        src: "/images/truckNRollEdition2023.jpg",
        alt: "Truck N Roll En Coeur Edition 2023"
    };

    const listStyle = {
        paddingBottom: '10px',
        textAlign: 'justify',
    };

    return (
        <Container className="pt-3">
            <Remerciments />

            <Container style={{ textAlign: "center" }}>
                <h2 className="p-3 fw-bolder">{t("home.description.title")}</h2>
                <p>{t("home.description.text")}</p>
                <div style={{ width: 'auto', margin: '0 auto' }}>
                    <Link to={"https://en-coeur.org/"} className="d-inline-block pb-3">
                        <img src="/images/fondation-en-coeur-logo.jpg" alt={"en-coeur.org"} />
                    </Link>
                </div>
                <p>
                    {t("home.description.link")}
                    <Link to="https://en-coeur.org/" className="link-danger" target="_blank">
                        en-coeur.org
                    </Link>
                </p>
            </Container>

            <Container style={{ textAlign: "center" }} className='p-3'>
                <h2 className="p-3 fw-bolder">{t("home.starKids.title")}</h2>
                <SideBySidePicturesKids image1={imageEnfant1} image2={imageEnfant2} />
            </Container>

            <Container style={{ textAlign: "center" }} className='p-3'>
                <h2 className="p-3 fw-bolder">{t("home.starTrucks")}</h2>
                <SideBySidePictures image1={imageTruck1} image2={imageTruck2} />
            </Container>

            <Container className='p-3'>
                <h2 className='text-center p-3 fw-bolder'>{t("home.history.title")}</h2>
                <Row className='p-2'>
                    <Col md={6}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            <li style={listStyle}>{t("home.history.par1")}</li>
                            <li style={listStyle}>{t("home.history.par2")}</li>
                            <li style={listStyle}>{t("home.history.par3")}</li>
                            <li style={listStyle}>{t("home.history.par4")}</li>
                            <li style={listStyle}>{t("home.history.par5")}</li>
                        </ul>
                    </Col>
                    <Col md={6} className='text-center d-flex flex-column justify-content-center align-items-center'>
                        <CustomImage image={imageEdition2023} handleOnClick={() => {
                        }} />
                        <CustomImage image={imageEdition2022} handleOnClick={() => {
                        }} />
                        <Link to={"https://en-coeur.org/"} target="_blank" className="py-3">
                            <img src="/images/fondation-en-coeur-logo.jpg" alt={"en-coeur.org"} />
                        </Link>
                    </Col>
                    <Container className='p-4 text-center'>
                        <ButtonxPayrience text={"home.history.button"} />
                    </Container>
                </Row>
            </Container>
        </Container>
    );
}

export default Home;
