function Header() {
    return (
        <div style={{ position: 'relative', textAlign: 'center' }}>
            <img
                src="/images/banderole2.jpg"
                alt="Background"
                style={{ maxWidth: '100%', height: '100%', minHeight: '90px', objectFit: 'cover' }}
            />
        </div>
    );
}

export default Header;
